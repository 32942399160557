<template>
  <b-card
    style="height: 200px"
  >
    <b-card-title> {{ titleOperationType[title] || title }}</b-card-title>
    <b-row class="px-1 align-items-center">
      <b-media-aside
        class="mr-2"
      >
        <b-avatar
          size="36"
          variant="light-success"
        >
          <feather-icon
            size="18"
            icon="DollarSignIcon"
          />
        </b-avatar>
      </b-media-aside>

      <div class="d-flex flex-column">
        <span class="font-weight-bold"> {{ value | value }} </span>
        <small> Total Gasto </small>
      </div>
    </b-row>

    <b-row class="px-1 align-items-center mt-1">
      <b-media-aside
        class="mr-2"
      >
        <b-avatar
          size="36"
          variant="light-primary"
        >
          <feather-icon
            size="18"
            icon="TrendingUpIcon"
          />
        </b-avatar>
      </b-media-aside>

      <div class="d-flex flex-column">
        <span class="font-weight-bold"> {{ total }} </span>
        <small> Total de Operações </small>
      </div>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BAvatar,
  BMediaAside,
  BCardTitle,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BRow,
    BAvatar,
    BMediaAside,
    BCardTitle,
  },
  props: {
    title: {
      type: String,
      default: () => '',
    },

    value: {
      type: Number,
      default: () => 0,
    },

    total: {
      type: Number,
      default: () => 0,
    },

    selectedActionType: {
      type: Number,
      default: () => 0,
    },

    item: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      titleOperationType: {
        opt_ins: 'Opt-Ins',
        opt_in_updates: 'Atualização de Opt-In',
        opt_outs: 'Opt-Out',
        endorsements: 'Averbação',
        endorsement_updates: 'Atualização de Averbação',
        schedule_searches: 'Consultas',
        endorsement_charges: 'Abertura de Avebação',
      },
    }
  },
}
</script>
