<template>
  <b-container
    v-if="!isFetching"
    fluid
  >
    <b-card>
      <b-row>
        <b-col
          md="6"
          sm="12"
        >
          <b-form-group
            label="Usuário"
            label-for="user"
          >
            <b-form-select
              id="user"
              v-model="userSelected"
              :options="optionsOsirisUsers"
            />
          </b-form-group>
        </b-col>

        <b-col
          :md="6"
          sm="12"
        >
          <b-form-group
            label="Mês"
            label-for="month"
          >
            <b-form-select
              id="month"
              v-model="monthSelected"
              :options="optionsMonths"
            />
          </b-form-group>
        </b-col>

        <b-button
          variant="primary"
          class="m-auto"
          @click="handleSearchClickButton"
        >
          Procurar
        </b-button>
      </b-row>
    </b-card>
    <b-card
      class="card-statistics"
    >
      <b-card-body>
        <b-row class="d-flex justify-content-around">
          <b-col
            v-for="item in statisticsItems"
            :key="item.title"
            md="auto"
            sm="6"
            class="mb-2 mb-md-0"
            :class="item.customClass"
          >
            <b-media no-body>
              <b-media-aside
                class="mr-2"
              >
                <b-avatar
                  size="48"
                  :variant="item.color"
                >
                  <feather-icon
                    size="24"
                    :icon="item.icon"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  {{ item.title }}
                </h4>
                <b-card-text class="font-small-3 mb-0">
                  {{ item.subtitle }}
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>

    <b-row>
      <b-col
        v-for="item in extractData.filter(item => item.title !== 'relationship_charges')"
        :key="item.title"
        lg="3"
        md="4"
        sm="6"
      >
        <extract-card
          v-b-modal="'modal-scrollable'"
          :title="item.title"
          :total="item.item.total"
          :value="item.item.value"
          :selected-action-type="filterActionType"
        />
      </b-col></b-row>

    <b-card>
      <b-row>
        <b-col
          md="4"
        >
          <b-form-group
            label="Tipo"
            label-for="type"
          >
            <b-form-select
              id="type"
              v-model="filterActionType"
              class="mb-1"
              :options="optionsFilterActionType"
            />
          </b-form-group>
        </b-col>

        <b-col
          md="4"
        >
          <b-form-group
            label="Data Inicial"
            label-for="initialDate"
          >
            <b-form-input
              id="initialDate"
              v-model="firstFilterDate"
              class="mb-1"
              type="date"
            />
          </b-form-group>
        </b-col>

        <b-col
          md="4"
        >
          <b-form-group
            label="Data Final"
            label-for="finalDate"
          >
            <b-form-input
              id="finalDate"
              v-model="finalFilterDate"
              class="mb-1"
              type="date"
            />
          </b-form-group>
        </b-col>

      </b-row>
      <b-table
        class="text-center"
        :items="extractLogsTableItems"
        :current-page="currentPage"
        striped
        :per-page="10"
        :fields="[
          { key: 'id', label: 'ID' },
          { key: 'rate', label: 'Taxa' },
          { key: 'type', label: 'Tipo' },
          { key: 'created_at', label: 'Data Operação' },
        ]"
      >
        <template #cell(created_at)="data">
          {{ data.item.created_at | date_hour }}
        </template>

        <template #cell(rate)="data">
          {{ data.item.rate | value }}
        </template>

        <template #cell(type)="data">
          {{ actionsTranslated[data.item.action_type.type] || data.item.action_type.type }}
        </template>
      </b-table>

      <b-pagination
        v-model="currentPage"
        :total-rows="extractLogsTableItems.length"
        :per-page="10"
        align="center"
        size="sm"
        class="mt-2"
      />
    </b-card>
  </b-container>
</template>

<script>
import {
    BContainer,
    BCard,
    BRow,
    BCol,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BFormInput,
    BTable,
    BPagination,
    BFormSelect,
    BFormGroup,
    BButton,
} from 'bootstrap-vue';

import ExtractCard from './components/ExtractCard.vue';

import RatesService from '@/service/credit_platform/rates';
import GroupsService from '@/service/credit_platform/groups';

import { callLoading } from '@/service/utilities/';

const months = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
];

export default {
    components: {
        BButton,
        BContainer,
        BCard,
        BRow,
        BCol,
        BCardText,
        BCardBody,
        BMedia,
        BAvatar,
        BMediaAside,
        BMediaBody,
        BTable,
        BFormInput,
        BFormSelect,
        BFormGroup,
        BPagination,
        ExtractCard,
    },

    data() {
        return {
            isFetching: false,
            currentPage: 1,
            extractData: {},
            extractLogs: [],
            firstFilterDate: '',
            finalFilterDate: '',
            isAdmin: false,
            id: 0,
            userSelected: 1,
            monthSelected: new Date().getMonth() + 1,
            statisticsItems: [
                {
                    icon: 'DollarSignIcon',
                    color: 'light-success',
                    title: '',
                    subtitle: 'Total Gasto',
                    customClass: '',
                },

                {
                    icon: 'TrendingUpIcon',
                    color: 'light-primary',
                    title: '',
                    subtitle: 'Total de Operações',
                    customClass: '',
                },
            ],
            filterActionType: null,
            // optionsFilterActionType: [
            //     { text: 'SELECIONE UM TIPO', value: null },
            //     { text: 'CRIAÇÃO DE OPT-IN', value: 1 },
            //     { text: 'UPDATE_OPT_IN', value: 2 },
            //     { text: 'SEARCH_OPT_IN', value: 3 },
            //     { text: 'REJECT_OPT_IN', value: 4 },
            //     { text: 'OPT_OUT', value: 5 },
            //     { text: 'CREATE_ENDORSEMENT', value: 6 },
            //     { text: 'UPDATE_ENDORSEMENT', value: 7 },
            //     { text: 'SEARCH_ENDORSEMENT', value: 8 },
            //     { text: 'NEW_SEARCH_SCHEDULE', value: 9 },
            //     { text: 'CREATE_ENDORSEMENT_DRAFT', value: 10 },
            //     { text: 'UPDATE_ENDORSEMENT_DRAFT', value: 11 },
            //     { text: 'DELETE_ENDORSEMENT_DRAFT', value: 12 },
            //     { text: 'SEARCH_SCHEDULE', value: 13 },
            //     { text: 'RELATIONSHIP_CHARGE', value: 14 },
            //     { text: 'ABERTURA DE AVERBAÇÃO', value: 15 },
            // ],
            actionsTranslated: {
                'CREATE OPT IN': 'CRIAÇÃO DE OPT-IN',
                'ENDORSEMENT CHARGE': 'ABERTURA DE AVERBAÇÃO',
                'CREATE ENDORSEMENT': 'CRIAÇÃO DE AVERBAÇÃO',
                'NEW SEARCH SCHEDULE': 'NOVA CONSULTA',
            },
            optionsOsirisUsers: [],
            titleOperationType: {
                opt_ins: 'Criação de opt-in',
                opt_in_updates: 'Edição de opt-in',
                opt_outs: 'Opt-out',
                endorsements: 'Criar averbação',
                endorsement_updates: 'Edição de averbação',
                schedule_searches: 'Consulta de agenda',
                endorsement_charges: 'Manutenção de Avebação',
            },
        };
    },

    computed: {
        optionsFilterActionType() {
            const arr = this.extractLogs.map(({ action_type }) => ({
                text: this.actionsTranslated[action_type.type] || action_type.type,
                value: action_type.id,
            }));
            // eslint-disable-next-line no-return-assign
            const sortedArr = arr.filter(a => !this[JSON.stringify(a)] && (this[JSON.stringify(a)] = true), Object.create(null)).sort((a, b) => a.text.localeCompare(b.text));

            sortedArr.unshift({
                text: 'SELECIONE UM TIPO',
                value: null,
            });

            return sortedArr;
        },

        extractLogsTableItems() {
            let arr = this.extractLogs;

            if (this.firstFilterDate) {
                const [year, month, day] = this.firstFilterDate.split('-');
                const date = new Date(year, month - 1, day);
                arr = arr.filter(item => {
                    const createdAt = new Date(item.created_at);
                    return createdAt >= date;
                });
            }

            if (this.finalFilterDate) {
                const [year, month, day] = this.finalFilterDate.split('-');
                const date = new Date(year, month - 1, day);
                arr = arr.filter(item => {
                    const createdAt = new Date(item.created_at);
                    return createdAt <= date;
                });
            }

            if (this.filterActionType) {
                arr = arr.filter(item => item.action_type_id === this.filterActionType);
            }

            return arr;
        },

        optionsMonths() {
            const options = months.map((item, index) => ({
                text: item,
                value: index + 1,
            }));

            return options;
        },
    },

    watch: {
        isFetching(value) {
            callLoading(value);
        },
    },

    async mounted() {
        this.isFetching = true;
        await Promise.all([
            this.getAllUsers(),
            this.getExtract(),
        ]);
        this.isFetching = false;
    },

    methods: {
        async getExtract(groupId = 1, month = '') {
            this.isFetching = true;
            // eslint-disable-next-line no-nested-ternary
            const { data } = month
                ? await RatesService.extractByGroupIdAndMonth(groupId, month)
                : await RatesService.extractByGroupId(groupId);

            this.statisticsItems[0].title = `R$ ${String(data.value).replace('.', ',')}`;
            this.statisticsItems[1].title = data.total;

            const arr = [];

            // eslint-disable-next-line no-restricted-syntax
            for (const [item, index] of Object.entries(data)) {
                arr.push({
                    item: index,
                    title: item,
                });
            }

            arr.shift();
            arr.shift();
            arr.pop();
            this.extractData = arr;
            this.extractLogs = data.extract_logs.reverse();
            this.isFetching = false;
        },

        async getAllUsers() {
            this.isFetching = true;
            const { status, data: { data } } = await GroupsService.getAllGroups();

            if (status === 200) {
                const allOsirisUsers = data.map(item => ({
                    value: item.id,
                    text: item.name,
                }));

                this.optionsOsirisUsers = allOsirisUsers.sort((a, b) => a.text.localeCompare(b.text));
                this.isFetching = false;
            }
        },

        handleSearchClickButton() {
            this.getExtract(this.userSelected, this.monthSelected);
        },
    },
};
</script>
